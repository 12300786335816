var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loadedMood
    ? _c(
        "v-layout",
        {
          staticClass: "mood-edit-card",
          attrs: { row: "", wrap: "", "pt-3": "", "gap-xs-3": "" }
        },
        [
          _vm.currentStep === 0
            ? _c(
                "v-flex",
                { attrs: { xs12: "", md8: "" } },
                [
                  _c("add-mood-emotions", {
                    key: _vm.currentStep,
                    attrs: {
                      cancellable: "",
                      form: _vm.loadedMood,
                      submit: _vm.advanceStep
                    },
                    on: {
                      "update:form": function($event) {
                        _vm.loadedMood = $event
                      }
                    }
                  })
                ],
                1
              )
            : _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("add-mood-intensities", {
                    key: _vm.currentStep + 1,
                    attrs: {
                      cancellable: "",
                      mood: _vm.loadedMood,
                      loading: _vm.loading,
                      back: _vm.goBackStep
                    },
                    on: { "mood-submit": _vm.submitMood }
                  })
                ],
                1
              )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }