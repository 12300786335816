<template>
<v-layout row wrap pt-3 gap-xs-3 class="mood-edit-card" v-if="loadedMood">
  <v-flex xs12 md8 v-if="currentStep === 0">
    <add-mood-emotions
            cancellable
            :key="currentStep"
            :form.sync="loadedMood"
            :submit="advanceStep">
    </add-mood-emotions>
  </v-flex>

  <v-flex xs12 v-else>
    <add-mood-intensities
            cancellable
            :key="currentStep + 1"
            :mood="loadedMood"
            :loading="loading"
            :back="goBackStep"
            @mood-submit="submitMood">
    </add-mood-intensities>
  </v-flex>
</v-layout>
</template>

<script>
import { format } from 'date-fns';
import addMoodEmotions from '@/views/tools/moods/components/addMood/add-mood-emotions.vue';
import addMoodIntensities from '@/views/tools/moods/components/addMood/add-mood-intensities.vue';

export default {
  name: 'addMoodHome',
  components: {
    addMoodEmotions,
    addMoodIntensities,
  },
  props: {
    mood: {
      type: Promise,
      required: true,
    },
  },
  data() {
    return {
      loadedMood: null,
      loading: false,
      currentStep: 0,
    };
  },
  mounted() {
    this.$log.debug('Started updating mood', this.mood);
    this.mood.then(res => {
      this.loadedMood = res;
    });
  },
  methods: {
    advanceStep() {
      this.$el.scrollTop = 0;
      if (this.currentStep < 1 && !this.disableNext) this.currentStep += 1;
    },
    goBackStep() {
      if (this.currentStep === 1) this.currentStep -= 1;
    },
    submitMood(event) {
      const toSave = { ...this.loadedMood, ...event };
      this.loading = true;
      this.$store.dispatch('moods/editMood', toSave)
        .then(res => {
          const successMessage = this.$t('app.tools.mood_tracker.notifications.updated');
          if (res && (!res.status || res.status < 300)) {
            const date = format(new Date(res.timestamp), 'MM-dd-yy');
            this.$store.commit('setNotification', { text: successMessage });
            this.$router.push({ name: 'moods-list', query: { d: date, tab: '1' } });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
